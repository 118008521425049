import React from 'react'

import Layout from 'src/components/layout/index'
import SEO from 'src/components/seo/index'
import Footer from 'src/components/footer/index'

import { Scrollin, ScrollItem } from 'src/components/scrollin/index'

import styles from './index.module.scss'


const PrivacyPage = () => (
    <React.Fragment >
        <Layout className={styles.privacy}>
            <SEO lang="ja" title="プライバシーポリシー" path="/privacypolicy/" description="作家・栗原明志(クリハラ アカシ)のウェブサイトです。2019年9月に作品社から『浮きよばなれ〜島国の彼岸へと漕ぎ出す日本文学芸術論』を上梓。" />

            <Scrollin rate={0.1} className={styles.title}>
                <ScrollItem tagName="h1">Privacy Policy</ScrollItem>

                <div className={styles.lead}>
                    <ScrollItem delay={100} className={styles.lastUpdated}>Last updated January 6th 2020.</ScrollItem>
                    <ScrollItem delay={200} className={styles.text}>このプライバシーポリシー（以下「本ポリシー」といいます。）は、株式会社TBR（以下「運営者」といいます。）のウェブサイト（「本ウェブサイト」を意味します。以下「本ウェブサイト」といいます。）を利用する方（以下「ユーザー」といいます。）の情報（以下「ユーザー情報」といいます。）の取扱いについて定めるものです。</ScrollItem>
                </div>
            </Scrollin>

            <div className={styles.content}>

                <Scrollin rate={0.1} tagName="section" className={styles.section}>
                    <ScrollItem tagName="h2" delay={100}>１．定義</ScrollItem>
                    <ScrollItem delay={200} className={styles.out}>
                        <p>本ポリシーにおいて、「個人情報」とは、個人情報の保護に関する法律第2条第1項により定義される個人情報をいいます。</p>
                    </ScrollItem>
                </Scrollin>

                <Scrollin rate={0.1} tagName="section" className={styles.section}>
                    <ScrollItem tagName="h2" delay={100}>２．取得する情報およびその利用目的等</ScrollItem>
                    <div>
                        <ScrollItem delay={200} className={styles.mb + ' ' + styles.out}>
                            <p>運営者は、本ウェブサイトにおいて、以下の各号に定めるユーザー情報を、以下の各号に定める場合に、各号に定める目的で利用するために、ユーザーから取得し、または将来的に取得する可能性があります。</p>
                        </ScrollItem>
                        <ol className={styles.list1}>
                            <li>
                                <div className={styles.flex}>
                                    <ScrollItem tagName="span" delay={300} className={styles.out}>（１）</ScrollItem>
                                    <table>
                                        <tbody>
                                            <ScrollItem tagName="tr" delay={400} className={styles.out}><th>ユーザー情報</th><td>メールアドレス及び氏名</td></ScrollItem>
                                            <ScrollItem tagName="tr" delay={500} className={styles.out}><th>取得時</th><td>Contactへのお問い合わせ時</td></ScrollItem>
                                            <ScrollItem tagName="tr" delay={600} className={styles.out}><th>利用目的</th><td>① お問い合わせへの返信及び回答<br />② イベントや書籍販売のお知らせのための連絡</td></ScrollItem>
                                        </tbody>
                                    </table>
                                </div>
                            </li>
                            <li>
                                <div className={styles.flex}>
                                    <ScrollItem tagName="span" delay={700} className={styles.out}>（２）</ScrollItem>
                                    <table>
                                        <tbody>
                                            <ScrollItem tagName="tr" delay={800} className={styles.out}><th>ユーザー情報</th><td>ユーザーの本ウェブサイト利用にかかるクッキーその他行動履歴（閲覧したページ、クリック、アクティビティ、利用環境、端末情報等）</td></ScrollItem>
                                            <ScrollItem tagName="tr" delay={900} className={styles.out}><th>取得時</th><td>本ウェブサイト利用時</td></ScrollItem>
                                            <ScrollItem tagName="tr" delay={1000} className={styles.out}><th>利用目的</th><td>ユーザーの利用状況を把握し、本ウェブサイトの改善や新サービスの開発に役立てるため</td></ScrollItem>
                                        </tbody>
                                    </table>
                                </div>
                            </li>
                        </ol>
                    </div>
                </Scrollin>

                <Scrollin rate={0.1} tagName="section" className={styles.section}>
                    <ScrollItem tagName="h2" delay={100}>３．第三者への提供等</ScrollItem>
                    <div>
                        <ol className={styles.list1}>
                            <li>
                                <div>
                                    <ScrollItem delay={200} className={styles.mb + ' ' + styles.out}>
                                        <p>（１）運営者は、ユーザー情報のうち個人情報に該当するものについて、以下の各号に定める場合を除いて、第三者に提供しません。</p>
                                    </ScrollItem>
                                    <ol className={styles.list2}>
                                        <ScrollItem tagName="li" delay={300} className={styles.out}>① 第三者に提供することについてあらかじめユーザーの同意を得た場合</ScrollItem>
                                        <ScrollItem tagName="li" delay={400} className={styles.out}>② 法令に基づく場合</ScrollItem>
                                        <ScrollItem tagName="li" delay={500} className={styles.out}>③ 人の生命、身体または財産の保護のために必要がある場合であって、本人の同意を得ることが困難である場合</ScrollItem>
                                        <ScrollItem tagName="li" delay={600} className={styles.out}>④ 公衆衛生の向上または児童の健全な育成の推進のために特に必要がある場合であって、本人の同意を得ることが困難である場合</ScrollItem>
                                        <ScrollItem tagName="li" delay={700} className={styles.out}>⑤ 国の機関もしくは地方公共団体またはその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、本人の同意を得ることにより当該事務の遂行に支障を及ぼすおそれがある場合</ScrollItem>
                                    </ol>
                                </div>
                            </li>
                            <li>
                                <div>
                                    <ScrollItem delay={800} className={styles.mb + ' ' + styles.out}>
                                        <p>（２）運営者は、本ウェブサイトの改良等を目的として本ウェブサイトの利用頻度等を測定するために、外部サービスであるGoogle Analyticsを利用し、匿名のトラフィックデータを収集しています。この行為により利用者個人が特定されることはありません。Google Analyticsの収集する情報およびその取扱いについては、以下の同社のプライバシーポリシー等をご参照ください。</p>
                                    </ScrollItem>
                                    <ScrollItem delay={900} className={styles.mb + ' ' + styles.out}>
                                        <p>Googleプライバシーポリシー</p>
                                        <a href="https://policies.google.com/privacy?hl=ja" target="_blank" rel="noopener noreferrer">https://policies.google.com/privacy?hl=ja</a>
                                    </ScrollItem>
                                    <ScrollItem delay={1000} className={styles.mb + ' ' + styles.out}>
                                        <p>Google アナリティクス利用規約</p>
                                        <a href="https://marketingplatform.google.com/about/analytics/terms/jp/" target="_blank" rel="noopener noreferrer">https://marketingplatform.google.com/about/analytics/terms/jp/</a>
                                    </ScrollItem>
                                    <ScrollItem delay={1100} className={styles.mb + ' ' + styles.out}>
                                        <p>GOOGLE のサービスを使用するサイトやアプリから収集した情報の GOOGLE による使用</p>
                                        <a href="https://policies.google.com/technologies/partner-sites?hl=ja" target="_blank" rel="noopener noreferrer">https://policies.google.com/technologies/partner-sites?hl=ja</a>
                                    </ScrollItem>
                                </div>
                            </li>
                        </ol>
                    </div>
                </Scrollin>

                <Scrollin rate={0.1} tagName="section" className={styles.section}>
                    <ScrollItem tagName="h2" delay={100}>４．開示および訂正等</ScrollItem>
                    <div>
                        <ol className={styles.list1}>
                            <li>
                                <div>
                                    <ScrollItem delay={200} className={styles.out}>
                                        <p>（１）ユーザーは、運営者に対して、運営者が保有する個人情報の開示を、運営者が別途指定する手続きによって、請求することができます。ただし、次の各号の場合はこの限りではありません。</p>
                                    </ScrollItem>
                                    <ol className={styles.list2}>
                                        <ScrollItem tagName="li" delay={300} className={styles.out}>① ユーザー本人が請求していることが確認できない場合</ScrollItem>
                                        <ScrollItem tagName="li" delay={400} className={styles.out}>② ユーザー本人または第三者の生命、身体、財産その他の権利利益を害するおそれがある場合</ScrollItem>
                                        <ScrollItem tagName="li" delay={500} className={styles.out}>③ 運営者の業務の適正な実施に著しい支障を及ぼすおそれがある場合</ScrollItem>
                                        <ScrollItem tagName="li" delay={600} className={styles.out}>④ 法令に違反することとなる場合</ScrollItem>
                                    </ol>
                                </div>
                            </li>
                            <li>
                                <ScrollItem delay={700} className={styles.out}>
                                    <p>（２）ユーザーは、運営者が保有する当該ユーザーの個人情報の内容が事実でない場合は、運営者が別途指定する手続きによって、当該個人情報の内容の訂正、追加または削除（以下「訂正等」といいます。）を請求することができます。この場合、運営者は当該個人情報の利用目的の達成に必要な範囲内において、遅滞なく必要な調査を行い、その結果に基づき、当該個人情報の内容の訂正等を行います。</p>
                                </ScrollItem>
                            </li>
                            <li>
                                <ScrollItem delay={800} className={styles.out}>
                                    <p>（３）第１項の開示手続きにあたって、運営者は、ユーザー本人による個人情報開示請求であることを確認するために必要な限度で、ユーザーの身分証明にかかる書類等の写しの提出を求めることができます。</p>
                                </ScrollItem>
                            </li>
                        </ol>
                    </div>
                </Scrollin>

                <Scrollin rate={0.1} tagName="section" className={styles.section}>
                    <ScrollItem tagName="h2" delay={100}>５．改定</ScrollItem>
                    <ScrollItem delay={200} className={styles.out}>
                        <p>運営者は、必要に応じて、本ポリシーを変更します。本ポリシーの変更の場合には、変更後の本ポリシーの施行時期及び内容を本ウェブサイト上で表示します。</p>
                    </ScrollItem>
                </Scrollin>

                <Scrollin rate={0.1} tagName="section" className={styles.section}>
                    <ScrollItem tagName="h2" delay={100}>６．お問い合わせ</ScrollItem>
                    <ScrollItem delay={200} className={styles.out}>
                        <p className={styles.mb}>本プライバシーポリシーに関するお問い合せは、以下のメールアドレスまでお願い致します。</p>
                        <p><a href="mailto:info@t-b-r.co.jp">info@t-b-r.co.jp</a></p>
                    </ScrollItem>
                </Scrollin>
            </div>

        </Layout>

        <Footer />

    </React.Fragment>
)

export default PrivacyPage
